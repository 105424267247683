import { CardView } from "../../../components/Card/Card";
import { Card } from "../../../types/card";
import { InPlayTrickStateEnum } from "../../../types/inPlayTrick";

export interface PropsType {
  currentPlayersTurn: boolean;
  currentTrickState: InPlayTrickStateEnum;
  woodcutterCard: Card | undefined;
  onFoxSkip: () => void;
  warningMessage: string | null;
}

export const TrickStatePrompt = ({
  currentPlayersTurn,
  currentTrickState,
  onFoxSkip,
  woodcutterCard,
  warningMessage,
}: PropsType) => {
  return (
    <>
      {currentPlayersTurn &&
        (currentTrickState === InPlayTrickStateEnum.LeadingPlayerFox ||
          currentTrickState === InPlayTrickStateEnum.FollowingPlayerFox) && (
          <div className="row-container" style={{ backgroundColor: "#F2A398" }}>
            <h3>
              You played a fox!{" "}
              <span style={{}}>
                Pick a card below to swap with the decree card or{" "}
                <a href="#" onClick={onFoxSkip}>
                  skip
                </a>
              </span>
            </h3>
          </div>
        )}
      {currentPlayersTurn &&
        (currentTrickState === InPlayTrickStateEnum.LeadingPlayerWoodcutter ||
          currentTrickState ===
            InPlayTrickStateEnum.FollowingPlayerWoodcutter) &&
        woodcutterCard && (
          <div className="row-container" style={{ backgroundColor: "#F2A398" }}>
            <h3>
              You played a woodcutter and got{" "}
              <CardView card={woodcutterCard} onClick={null} />
              <span style={{ color: "#CC0000" }}>
                Pick a card below to return to the deck
              </span>
            </h3>
          </div>
        )}
      {warningMessage && (
        <>
          <div className="row-container" style={{ backgroundColor: "#F2A398" }}>
            <h3>{warningMessage}</h3>
          </div>
        </>
      )}
    </>
  );
};
