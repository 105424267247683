import { Card, CardSuit } from "../../types/card";

interface CardProps {
  card: Card;
  onClick: ((card: Card) => void) | null;
}

export const CardView = ({ card, onClick }: CardProps) => {
  let suitName = "Bell";
  if (card.suit === CardSuit.Key) {
    suitName = "Key";
  }
  if (card.suit === CardSuit.Moon) {
    suitName = "Moon";
  }
  return (
    <>
      <div
        style={{
          border: "1px solid black",
          marginLeft: "10px",
          padding: "2px 5px",
        }}
        className={
          "card-box " +
          (onClick !== null ? " card-box-hoverable" : "") +
          (card.suit === CardSuit.Key ? " key" : "") +
          (card.suit === CardSuit.Moon ? " moon" : "") +
          (card.suit === CardSuit.Bell ? " bell" : "")
        }
        onClick={() => {
          if (onClick !== null) {
            onClick(card);
          }
        }}
      >
        <h4>Suit: {suitName}</h4>
        <h4>Value: {card.value}</h4>
        {card.value == 1 && (
          <>
            <h4>Swan!</h4>
            {onClick !== null && <p>You will lead the next trick</p>}
          </>
        )}
        {card.value == 3 && (
          <>
            <h4>Fox!</h4>
            {onClick !== null && <p>You can swap a card for the decree card</p>}
          </>
        )}
        {card.value == 5 && (
          <>
            <h4>Woodcutter!</h4>
            {onClick !== null && <p>Get a card from the deck and discard 1</p>}
          </>
        )}
        {card.value == 7 && (
          <>
            <h4>Treasure!</h4>
            {onClick !== null && <p>Winner of this trick gets another point</p>}
          </>
        )}
        {card.value == 9 && (
          <>
            <h4>Witch!</h4>
            {onClick !== null && <p>Treated as if it is the trump suit</p>}
          </>
        )}
        {card.value == 11 && (
          <>
            <h4>Monarch!</h4>
            {onClick !== null && (
              <p>
                If lead card, opponent must pick highest of suit or Swan of
                suit.
              </p>
            )}
          </>
        )}
      </div>
    </>
  );
};
