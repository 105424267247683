import { Game, PlayerSafeGame } from "../../../types/games";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../index";

interface gameInitialState {
  games: PlayerSafeGame[] | null;
  currentGame: PlayerSafeGame | null;
}

const initialState: gameInitialState = {
  games: null,
  currentGame: null,
};

export const GameSlice = createSlice({
  name: "GameSlice",
  initialState: initialState,
  reducers: {
    setGames: (state, action: PayloadAction<PlayerSafeGame[]>) => {
      state.games = action.payload;
    },
    setCurrentGame: (state, action: PayloadAction<PlayerSafeGame>) => {
      state.currentGame = action.payload;
    },
  },
});

export const { setGames, setCurrentGame } = GameSlice.actions;

export const selectGames = (state: RootState) => state.games.games;
export const selectActiveGame = (state: RootState) => state.games.currentGame;

export const GameReducer = GameSlice.reducer;
