export interface Game {
  gameId: string;
  status: GameStatus;
  createDate: Date;
  player1Info: PlayerInfo;
  player2Info: PlayerInfo | null;
  privateGame: boolean;
}

export interface PlayerSafeGame extends Game {
  currentUsersTurn: boolean;
}

export enum GameStatus {
  Pending = 0,
  InProgress = 1,
  Finished = 2,
}

export interface PlayerInfo {
  playerId: string;
  username: string;
}
