import { Button } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { GameViewBox } from "../../components/GameViewBox/GameViewBox";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import {
  selectLoggedInUserInfo,
  selectToken,
} from "../../store/slices/authentication";
import { selectGames, setGames } from "../../store/slices/game";

export const Games = () => {
  const dispatch = useAppDispatch();
  //  const [games, setGames] = useState([] as Game[]);
  const [loadedGames, setLoadedGames] = useState(false);
  const games = useAppSelector(selectGames);
  const loggedInUser = useAppSelector(selectLoggedInUserInfo);
  const loggedInToken = useAppSelector(selectToken);

  const newGameClick = (privateGame: boolean) => {
    console.log("env", process.env);
    console.log("Logged in token", loggedInToken);
    axios
      .post(
        process.env.REACT_APP_SERVER_URL +
          "/Game?PlayerId=" +
          loggedInUser?.playerId +
          "&PrivateGame=" +
          privateGame.toString(),
        {},
        {
          headers: { Authorization: "bearer " + loggedInToken },
        }
      )
      .then(() => {
        setLoadedGames(false);
      });
  };

  const refreshGamesList = () => {
    axios
      .get(process.env.REACT_APP_SERVER_URL + "/Game", {
        headers: { Authorization: "bearer " + loggedInToken },
      })
      .then((res) => {
        console.log("Refresh Games", res.data);
        setLoadedGames(true);
        dispatch(setGames(res.data));
      });
  };

  useEffect(() => {
    refreshGamesList();
    const interval = setInterval(refreshGamesList, 5000);
    return () => clearInterval(interval);
  }, [loadedGames]);

  if (loggedInUser === null) {
    return <h1>Not logged in</h1>;
  }

  return (
    <>
      <h1>Your Games</h1>
      <Button
        onClick={() => newGameClick(false)}
        variant={"outlined"}
        disabled={!loggedInUser}
      >
        New Public
      </Button>{" "}
      <Button
        onClick={() => newGameClick(true)}
        variant={"outlined"}
        disabled={!loggedInUser}
      >
        New Private
      </Button>{" "}
      <Button
        onClick={() => {
          setLoadedGames(false);
        }}
        variant={"outlined"}
      >
        Refresh
      </Button>
      <ul style={{ marginLeft: 0, paddingLeft: 0 }}>
        {games
          ?.filter(
            (x) =>
              (x.player1Info.playerId === loggedInUser.playerId ||
                x.player2Info?.playerId === loggedInUser.playerId) &&
              x.status !== 2
          )
          .map((x) => (
            <GameViewBox
              game={x}
              key={x.gameId}
              onRequireReload={() => {
                setLoadedGames(false);
              }}
            />
          ))}
      </ul>
      <h1>Your Completed Games</h1>
      <ul style={{ marginLeft: 0, paddingLeft: 0 }}>
        {games
          ?.filter(
            (x) =>
              (x.player1Info.playerId === loggedInUser.playerId ||
                x.player2Info?.playerId === loggedInUser.playerId) &&
              x.status === 2
          )
          .map((x) => (
            <GameViewBox
              game={x}
              key={x.gameId}
              onRequireReload={() => {
                setLoadedGames(false);
              }}
            />
          ))}
      </ul>
      <h1>Public Games</h1>
      <ul style={{ marginLeft: 0, paddingLeft: 0 }}>
        {games
          ?.filter(
            (x) =>
              x.player2Info?.playerId === undefined &&
              x.player1Info.playerId !== loggedInUser.playerId
          )
          .map((x) => (
            <GameViewBox
              game={x}
              key={x.gameId}
              onRequireReload={() => {
                setLoadedGames(false);
              }}
            />
          ))}
      </ul>
    </>
  );
};
