export interface Card {
  suit: CardSuit;
  value: number;
}

export enum CardSuit {
  Bell = 0,
  Moon = 1,
  Key = 2,
}
