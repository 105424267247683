import { CardView } from "../../../components/Card/Card";
import { PlayerEnum } from "../../../types/inPlayTrick";
import { PlayState } from "../../../types/playState";

export interface PropsType {
  activeGameState: PlayState;
}

export const GameStatusBar = ({ activeGameState }: PropsType) => {
  const yourScore =
    activeGameState.currentPlayer === PlayerEnum.Player1
      ? activeGameState.player1Score
      : activeGameState.player2Score;

  const opponentsScore =
    activeGameState.currentPlayer === PlayerEnum.Player1
      ? activeGameState.player2Score
      : activeGameState.player1Score;

  const yourTricks =
    activeGameState.currentPlayer === PlayerEnum.Player1
      ? activeGameState.player1Tricks
      : activeGameState.player2Tricks;

  const opponentsTricks =
    activeGameState.currentPlayer === PlayerEnum.Player1
      ? activeGameState.player2Tricks
      : activeGameState.player1Tricks;

  return (
    <>
      <div
        className="row-container"
        style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
      >
        <div className="GameStatusBarElement" style={{ marginRight: 25 }}>
          <h3>Scores:</h3>
          <p>
            Your Score: <b>{yourScore}</b>
          </p>
          <p>
            Opponents Score: <b>{opponentsScore}</b>
          </p>
        </div>
        <div className="GameStatusBarElement" style={{ marginRight: 25 }}>
          <h3>Tricks:</h3>
          <p>
            Your Tricks: <b>{yourTricks}</b>
          </p>
          <p>
            Opponents Tricks: <b>{opponentsTricks}</b>
          </p>
        </div>
        {activeGameState.lastTrick && (
          <div className="GameStatusBarElement" style={{ marginRight: 25 }}>
            <h3>Last Trick:</h3>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              {activeGameState.lastTrick.cards.map((c) => (
                <CardView card={c} onClick={null} />
              ))}
            </div>
          </div>
        )}
        <div className="GameStatusBarElement" style={{ marginRight: 25 }}>
          <h3>Last Trick Info:</h3>
          <h3>{activeGameState.lastTrickOutcomeMessage}</h3>
        </div>
      </div>
    </>
  );
};
