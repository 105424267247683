import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import {
  selectLoggedInUserInfo,
  selectToken,
} from "../../store/slices/authentication";
import { setCurrentGame } from "../../store/slices/game";
import { GameStatus, PlayerSafeGame } from "../../types/games";

export interface GameViewBoxProps {
  game: PlayerSafeGame;
  onRequireReload: () => void;
}

export const GameViewBox = ({ game, onRequireReload }: GameViewBoxProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [enteredUserId, setEnteredUserId] = useState<string>("");
  const loggedInToken = useAppSelector(selectToken);
  const currentUserInfo = useAppSelector(selectLoggedInUserInfo);
  const handleGameClick = () => {
    dispatch(setCurrentGame(game));
    navigate("/activegame");
  };

  const handleDeleteClick = () => {
    axios
      .post(
        process.env.REACT_APP_SERVER_URL + "/Game/delete?GameId=" + game.gameId,
        {},
        {
          headers: { Authorization: "bearer " + loggedInToken },
        }
      )
      .then(() => {
        onRequireReload();
      });
  };

  const handleAddPlayerClick = () => {
    axios
      .post(
        process.env.REACT_APP_SERVER_URL +
          "/Game/AssignPlayer2?GameId=" +
          game.gameId +
          "&Player2Id=" +
          currentUserInfo?.playerId,
        {},
        {
          headers: { Authorization: "bearer " + loggedInToken },
        }
      )
      .then(() => {
        onRequireReload();
      });
  };

  console.log("The game", game);
  return (
    <>
      <div
        className="row-container"
        style={{
          border: "1px solid #aa0000",
          backgroundColor:
            game.status === GameStatus.Finished
              ? "#a6ff96"
              : game.status === GameStatus.Pending
              ? "rgba(0, 0, 0, 0.1);"
              : game.currentUsersTurn
              ? "#A2F48B"
              : "#ff9696",
        }}
      >
        <h3>
          <a href={"#"} onClick={handleGameClick}>
            {game.player1Info.username} vs{" "}
            {game.player2Info ? game.player2Info.username : "???"} (
            {game.gameId})
          </a>
        </h3>
        {game.privateGame && <h4>This is a private game...</h4>}
        {game.currentUsersTurn && game.status === 1 && <h4>It's your turn!</h4>}
        {!game.currentUsersTurn && game.status === 1 && (
          <h4>It's not your turn!</h4>
        )}
        {game.player2Info === null && (
          <p>
            {game.player1Info.playerId === currentUserInfo?.playerId ? (
              <>Waiting on a player to join</>
            ) : (
              <>
                <input
                  type="button"
                  value="Join"
                  onClick={handleAddPlayerClick}
                />
              </>
            )}
          </p>
        )}
      </div>
    </>
  );
};
