export const HowTo = () => {
  return (
    <>
      <h1>How to Play</h1>
      <a
        href="https://www.ultraboardgames.com/the-fox-in-the-forest/game-rules.php"
        target={"_blank"}
      >
        Rules Explained
      </a>
      <h2>Special Cards</h2>
      <h3>Monarch (11)</h3>
      <p>
        When you lead with this card, if your opponent has any of the same suit,
        they must either play the Swan (1) or the highest card of that suit.
      </p>
      <h3>Witch (9)</h3>
      <p>
        When determining the winner of a trick with only one 9, treat the 9 as
        if it were in the trump suit.
      </p>
      <h3>Treasure (7)</h3>
      <p>The winner of the trick receives 1 point for each 7 in the trick</p>
      <h3>Woodcutter (5)</h3>
      <p>
        When played, you are drawn a card from the spare deck. Then discard any
        1 card in your hand .
      </p>
      <h3>Fox (3)</h3>
      <p>
        When you play this, you may exchange the decree card with a card from
        your hand.
      </p>
      <h3>Swan (1)</h3>
      <p>If you play this and lose the trick, you lead the next trick.</p>
    </>
  );
};
