import { Card } from "./card";

export interface InPlayTrick {
  leadingPlayer: PlayerEnum;
  followingPlayer: PlayerEnum;
  leadingCard?: Card;
  followingCard?: Card;
  inPlayTrickState: InPlayTrickStateEnum;
  woodcutterCard?: Card;
}

export interface CompleteTrick {
  cards: Card[];
  winner: PlayerEnum;
}

export enum PlayerEnum {
  Player1 = 0,
  Player2 = 1,
}

export enum PlayerPositionEnum {
  Leading = 0,
  Following = 1,
}

export enum InPlayTrickStateEnum {
  LeadingPlayer = 0,
  LeadingPlayerFox = 1,
  LeadingPlayerWoodcutter = 2,
  FollowingPlayer = 3,
  FollowingPlayerFox = 4,
  FollowingPlayerWoodcutter = 5,
  Complete = 6,
}
