import axios from "axios";
import { Link, Route, Routes } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../hooks/reduxHooks";
import { ActiveGame } from "../layouts/ActiveGame/ActiveGame";
import { Games } from "../layouts/Games/Games";
import { HowTo } from "../layouts/HowTo/HowTo";
import { Login } from "../layouts/Login/Login";
import {
  selectLoggedInUserInfo,
  selectToken,
  setLoggedInUserInfo,
} from "../store/slices/authentication";
import "../style/App.css";

function App() {
  const dispatch = useAppDispatch();
  const loggedInUserInfo = useAppSelector(selectLoggedInUserInfo);

  const token = useAppSelector(selectToken);

  if (token != null && loggedInUserInfo == null) {
    // Request userinfo from Db
    axios
      .get(process.env.REACT_APP_SERVER_URL + "/User/GetUserInfo", {
        headers: { Authorization: "bearer " + token },
      })
      .then((result) => {
        let userId = result.data.userId;
        let username = result.data.username;

        dispatch(
          setLoggedInUserInfo({
            playerId: userId,
            username: username,
          })
        );
      });
  }

  return (
    <>
      <div className="page-container">
        <div>
          <Link className="navbar-link" to="/games">
            Games
          </Link>
          <Link className="navbar-link" to="/login">
            Login
          </Link>
          <Link className="navbar-link" to="/howtoplay">
            Help
          </Link>
        </div>
        <div>
          {loggedInUserInfo && (
            <b>Logged in user: {loggedInUserInfo.username}</b>
          )}
          {!loggedInUserInfo && <b>Not logged in!</b>}
        </div>
        <div>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/games" element={<Games />} />
            <Route path="/login" element={<Login />} />
            <Route path="/activeGame" element={<ActiveGame />} />
            <Route path="/howtoplay" element={<HowTo />} />
          </Routes>
        </div>
      </div>
    </>
  );
}

export default App;
