import axios from "axios";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { CardView } from "../../components/Card/Card";
import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import {
  selectLoggedInUserInfo,
  selectToken,
} from "../../store/slices/authentication";
import { selectActiveGame, setCurrentGame } from "../../store/slices/game";
import { Card } from "../../types/card";
import { PlayerInfo } from "../../types/games";
import {
  InPlayTrickStateEnum,
  PlayerEnum,
  PlayerPositionEnum,
} from "../../types/inPlayTrick";
import { PlayState } from "../../types/playState";
import { Login } from "../Login/Login";
import { CurrentPlayerTurnBar } from "./components/CurrentPlayerTurnBar";
import { GameStatusBar } from "./components/GameStatusBar";
import { InPlayTrickBar } from "./components/InPlayTrickBar";
import { TrickStatePrompt } from "./components/TrickStatePrompt";

export const ActiveGame = () => {
  const dispatch = useAppDispatch();
  const activeGame = useAppSelector(selectActiveGame);
  const currentUserInfo = useAppSelector(selectLoggedInUserInfo);
  const loggedInToken = useAppSelector(selectToken);
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeGameState, setActiveGameState] = useState<PlayState | null>(
    null
  );

  console.log("ACtive game", activeGame);

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handlePlayerSelectCard = async (card: Card | null) => {
    if (!currentPlayersTurn) {
      setErrorMessage("Not your turn!");
      return;
    }
    setErrorMessage(null);

    await axios
      .post(
        process.env.REACT_APP_SERVER_URL +
          "/Game/playeraction?player=" +
          currentUserInfo?.playerId,
        {
          GameId: activeGame?.gameId,
          ActionType: 0,
          ActionedCard: card ? { value: card.value, suit: card.suit } : null,
        },
        {
          headers: { Authorization: "bearer " + loggedInToken },
        }
      )
      .then((res) => {
        console.log("Play card response", res);
        if (
          res.data.errorMessage != undefined &&
          res.data.errorMessage !== null
        ) {
          setErrorMessage(res.data.errorMessage);
        }
      });

    loadActiveGameState();
  };

  const loadActiveGameState = async () => {
    axios
      .get(
        process.env.REACT_APP_SERVER_URL +
          "/Game/PlayState?gameId=" +
          activeGame?.gameId +
          "&playerId=" +
          currentUserInfo?.playerId,
        {
          headers: { Authorization: "bearer " + loggedInToken },
        }
      )
      .then((res) => {
        setActiveGameState(res.data);
        console.log("Active game stae", res.data);
      });
  };

  const handleFoxSkip = () => {
    handlePlayerSelectCard(null);
  };

  const RefreshActiveGame = (gameId: string | null) => {
    axios
      .get(process.env.REACT_APP_SERVER_URL + "/game/" + gameId, {
        headers: { Authorization: "bearer " + loggedInToken },
      })
      .catch((err) => {
        console.log("errored");
      })
      .then((res) => {
        // Check if res is a response or a void
        if (res === undefined) return;

        // Check if res.data is an ActiveGame interface or not
        if (res.data.gameId === undefined) return;

        console.log("Active game to beeee", res);
        dispatch(setCurrentGame(res.data));
      });
  };

  useEffect(() => {
    if (!activeGame) {
      // Do we have a query param with an Id? If so go and fetch this asappp
      console.log("Search Params!", searchParams.get("gameId"));
      RefreshActiveGame(searchParams.get("gameId"));
    }
  }, [activeGame]);

  useEffect(() => {
    if (!activeGame || activeGame.player2Info === null) return;
    loadActiveGameState();
    const interval = setInterval(loadActiveGameState, 2500);
    return () => clearInterval(interval);
  }, [currentUserInfo, activeGame]);

  useEffect(() => {
    // if (!activeGame || activeGame.player2Info === null) return;

    let gameId = "";
    if (activeGame) {
      gameId = activeGame.gameId;
    } else {
      gameId = searchParams.get("gameId")?.toString() ?? "";
    }

    setSearchParams({
      gameId: gameId,
    });
  }, [activeGame?.gameId, activeGame]);

  useEffect(() => {
    if (!activeGame) return;
    if (
      activeGame.player2Info === null &&
      currentUserInfo !== null &&
      activeGame.player1Info.playerId === currentUserInfo.playerId
    ) {
      const interval = setInterval(
        () => RefreshActiveGame(activeGame.gameId),
        2500
      );
      return () => clearInterval(interval);
    }
  }, [activeGame]);
  if (!activeGame) return <></>;

  const handleAddPlayerClick = () => {
    axios
      .post(
        process.env.REACT_APP_SERVER_URL +
          "/Game/AssignPlayer2?GameId=" +
          activeGame.gameId +
          "&Player2Id=" +
          currentUserInfo?.playerId,
        {},
        {
          headers: { Authorization: "bearer " + loggedInToken },
        }
      )
      .then(() => {
        RefreshActiveGame(activeGame.gameId);
      });
  };

  if (activeGame.player2Info === null && currentUserInfo === null) {
    return (
      <>
        <Login />
      </>
    );
  } else if (activeGame.player2Info === null && currentUserInfo !== null) {
    if (activeGame.player1Info.playerId !== currentUserInfo.playerId) {
      return (
        <>
          <h2>You can join this game!</h2>
          <button onClick={handleAddPlayerClick}>Join Game</button>
        </>
      );
    } else {
      // Wait two seconds and fresh the game

      return (
        <>
          <h2>Waiting for another player to join!</h2>
          <p>Share the URL with someone (they must log in first!)</p>
        </>
      );
    }
  }

  if (!activeGameState)
    return <>Something is wrong with the active game state?</>;

  const currentTrickState = activeGameState?.inPlayTrick.inPlayTrickState;
  const playerPosition =
    activeGameState?.inPlayTrick.leadingPlayer == activeGameState?.currentPlayer
      ? PlayerPositionEnum.Leading
      : PlayerPositionEnum.Following;

  const currentPlayersTurn =
    ((currentTrickState === InPlayTrickStateEnum.LeadingPlayer ||
      currentTrickState === InPlayTrickStateEnum.LeadingPlayerWoodcutter ||
      currentTrickState === InPlayTrickStateEnum.LeadingPlayerFox) &&
      playerPosition == PlayerPositionEnum.Leading) ||
    ((currentTrickState === InPlayTrickStateEnum.FollowingPlayer ||
      currentTrickState === InPlayTrickStateEnum.FollowingPlayerWoodcutter ||
      currentTrickState === InPlayTrickStateEnum.FollowingPlayerFox) &&
      playerPosition == PlayerPositionEnum.Following);

  const otherPlayerInfo =
    activeGame?.player1Info?.playerId == currentUserInfo?.playerId
      ? activeGame?.player2Info
      : activeGame?.player1Info;

  if (activeGameState.winner !== null) {
    if (
      activeGame?.player1Info === undefined ||
      activeGame.player2Info === undefined ||
      activeGame.player2Info === null
    ) {
      return <>AHhh</>;
    }
    let winner: PlayerInfo;
    let looser: PlayerInfo;
    let winningScore: number;
    let loosingScore: number;

    if (activeGameState.winner === PlayerEnum.Player1) {
      winner = activeGame.player1Info;
      winningScore = activeGameState.player1Score;
      looser = activeGame.player2Info;
      loosingScore = activeGameState.player2Score;
    } else {
      winner = activeGame.player2Info;
      winningScore = activeGameState.player2Score;
      looser = activeGame.player1Info;
      loosingScore = activeGameState.player1Score;
    }

    return (
      <>
        {" "}
        <div className="row-container">
          <h2>Your game vs {otherPlayerInfo?.username}</h2>
        </div>
        <div className="row-container">
          {winner.username} has won the game with {winningScore} points.{" "}
          {looser.username} has lost the game with {loosingScore}
        </div>
      </>
    );
  }

  if (!activeGame) {
    return <h1>No active game selected!</h1>;
  } else {
    return (
      <>
        <div style={{ width: "100%", marginTop: "1rem" }}>
          <div className="row-container">
            <h2>Your game vs {otherPlayerInfo?.username}</h2>
          </div>
          <CurrentPlayerTurnBar currentPlayersTurn={currentPlayersTurn} />
          {activeGameState.lastRoundOutcomeMessage &&
            activeGameState.currentPlayerCards.length == 12 && (
              <div
                className="row-container"
                style={{ backgroundColor: "#c8b1e3" }}
              >
                <h3>{activeGameState.lastRoundOutcomeMessage}</h3>
              </div>
            )}
          <GameStatusBar activeGameState={activeGameState} />
          <InPlayTrickBar activeGameState={activeGameState} />
          <TrickStatePrompt
            currentPlayersTurn={currentPlayersTurn}
            currentTrickState={currentTrickState}
            onFoxSkip={handleFoxSkip}
            woodcutterCard={activeGameState.inPlayTrick.woodcutterCard}
            warningMessage={errorMessage}
          />

          {activeGameState.inPlayTrick.leadingCard?.value === 11 &&
            playerPosition == PlayerPositionEnum.Following && (
              <div
                className="row-container"
                style={{ backgroundColor: "#F2A398" }}
              >
                <p>
                  Leading player played a Monarch. You must select the highest
                  card of the same suit or the Swan of that suit if possible!
                </p>
              </div>
            )}

          <div className="row-container">
            <h2>Your Cards</h2>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              {activeGameState.currentPlayerCards
                .sort((c1, c2) => {
                  if (c1.suit == c2.suit) {
                    return c2.value - c1.value;
                  } else {
                    return c2.suit - c1.suit;
                  }
                })
                .map((c) => (
                  <CardView
                    key={c.suit.toString() + c.value.toString()}
                    card={c}
                    onClick={currentPlayersTurn ? handlePlayerSelectCard : null}
                  />
                ))}
            </div>
          </div>
        </div>
      </>
    );
  }
};
