import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PlayerInfo } from "../../../types/games";
import { RootState } from "../../index";

interface authenticationInitialState {
  loggedInUserInfo: PlayerInfo | null;
  token: string | null;
}

const initialState: authenticationInitialState = {
  loggedInUserInfo: null,
  token: localStorage.getItem("token") || null,
};

export const AuthenticationSlice = createSlice({
  name: "AuthenticationSlice",
  initialState: initialState,
  reducers: {
    setLoggedInUserInfo: (state, action: PayloadAction<PlayerInfo | null>) => {
      state.loggedInUserInfo = action.payload;
    },
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
      localStorage.setItem("token", action.payload);
    },
    logout: (state) => {
      state.loggedInUserInfo = null;
      state.token = null;
      localStorage.removeItem("token");
    },
  },
});

export const {
  setLoggedInUserInfo: setLoggedInUserInfo,
  setToken,
  logout,
} = AuthenticationSlice.actions;

export const selectLoggedInUserInfo = (state: RootState) =>
  state.authentication.loggedInUserInfo;

export const AuthenticationReducer = AuthenticationSlice.reducer;

export const selectToken = (state: RootState) => state.authentication.token;
