import { CardView } from "../../../components/Card/Card";
import { PlayState } from "../../../types/playState";

export interface PropsType {
  activeGameState: PlayState;
}

export const InPlayTrickBar = ({ activeGameState }: PropsType) => {
  return (
    <>
      <div
        className="row-container"
        style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}
      >
        <div style={{ width: "260px" }}>
          <h3>Decree Card:</h3>
          {<CardView card={activeGameState.decreeCard} onClick={null} />}
        </div>
        <div style={{ width: "260px" }}>
          <h3>Leading Card:</h3>
          {activeGameState.inPlayTrick.leadingCard && (
            <CardView
              card={activeGameState.inPlayTrick.leadingCard}
              onClick={null}
            />
          )}
        </div>
        <div style={{ width: "260px" }}>
          <h3>Following Card:</h3>
          {activeGameState.inPlayTrick.followingCard && (
            <CardView
              card={activeGameState.inPlayTrick.followingCard}
              onClick={null}
            />
          )}
        </div>
      </div>
    </>
  );
};
