interface PropsType {
  currentPlayersTurn: boolean;
}

export const CurrentPlayerTurnBar = ({ currentPlayersTurn }: PropsType) => {
  return (
    <>
      {currentPlayersTurn && (
        <div className="row-container" style={{ backgroundColor: "#A2F48B" }}>
          <h2>Your turn!</h2>
        </div>
      )}
      {!currentPlayersTurn && (
        <div className="row-container" style={{ backgroundColor: "#F2A398" }}>
          <h2>Opponents turn!</h2>
        </div>
      )}
    </>
  );
};
