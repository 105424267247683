import { useAppDispatch, useAppSelector } from "../../hooks/reduxHooks";
import {
  logout,
  selectLoggedInUserInfo,
  selectToken,
  setLoggedInUserInfo,
  setToken,
} from "../../store/slices/authentication";
import { ChangeEvent, useState } from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import axios from "axios";

export const Login = () => {
  const dispatch = useAppDispatch();
  const loggedInUserInfo = useAppSelector(selectLoggedInUserInfo);
  const loggedInToken = useAppSelector(selectToken);
  const [loginError, setLoginError] = useState<string | null>(null);

  return (
    <>
      <h1>Login Area</h1>
      {loggedInUserInfo !== null && (
        <>
          <p>You are currently logged in as:</p>
          <p>{loggedInUserInfo?.username}</p>
        </>
      )}
      {loggedInUserInfo === null && (
        <>
          <p>Please login below</p>
          <Formik
            initialValues={{ username: "", password: "" }}
            onSubmit={(values) => {
              setLoginError(null);
              console.log("Submit values", values);
              axios
                .post(
                  process.env.REACT_APP_SERVER_URL +
                    "/authentication?Username=" +
                    values.username +
                    "&password=" +
                    values.password
                )
                .then((result) => {
                  console.log("Login result", result);
                  let token = result.data.token;
                  let userId = result.data.userId;
                  let username = result.data.username;

                  dispatch(setToken(token));
                  dispatch(
                    setLoggedInUserInfo({
                      playerId: userId,
                      username: username,
                    })
                  );
                })
                .catch((error) => {
                  console.log("Login error", error);
                  setLoginError("Login Failed, bad credentials?");
                });
            }}
            validate={() => {}}
          >
            <Form>
              <p>Username:</p>
              <Field type="text" name="username" />
              <ErrorMessage name="username" component="div" />
              <p>Password:</p>
              <Field type="password" name="password" />
              <ErrorMessage name="password" component="div" />
              <button type="submit" disabled={false}>
                Submit
              </button>
              <h2>{loginError !== null && <p>{loginError}</p>}</h2>
            </Form>
          </Formik>
        </>
      )}
      {loggedInUserInfo !== null && (
        <button onClick={() => dispatch(logout())}>Logout</button>
      )}
    </>
  );
};
