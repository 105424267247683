import {AnyAction,Reducer, combineReducers, configureStore} from "@reduxjs/toolkit";
import {appReducers} from "./slices";

const rootReducers = combineReducers(appReducers);

const rootReducersEnhancer: Reducer<RootState, AnyAction>=(state, action) => {
    return rootReducers(state, action);
};

export const store = configureStore({
    reducer: rootReducersEnhancer
});

export type RootState = ReturnType<typeof rootReducers>;
export type AppDispatch = typeof store.dispatch;